<template>
    <div id="app">
        <header>
            The current time is...
        </header>
        <div class="time">
            {{time}}
        </div>
        <div class="date">
            {{date}}
        </div>
        <select v-model="selectedTimeZone">
            <option v-for="zone in timeZones"
                    :key="zone">
                {{zone}}
            </option>
        </select>
        <footer>
            &copy; <a href="https://www.tractus.ca">Tractus Events</a>
        </footer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            timer: null,    
            selectedTimeZone: null,
            time: null,
            date: null,
        }
    },

    computed: {
        timeZones() {
            return Intl.supportedValuesOf('timeZone');
        }
    },

    methods: {
        tick() {
            let date = new Date;
            let strTime = date.toLocaleString("en-US", {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: false,                 
                timeZone: `${this.selectedTimeZone}`});

            let strDate = date.toLocaleString("en-US", {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                weekday: 'long',
                hour12: false,                 
                timeZone: `${this.selectedTimeZone}`});


            this.time = strTime;
            this.date = strDate;
        }
    },

    mounted() {
        this.selectedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.timer = setInterval(this.tick, 250);
    },

    beforeDestroy() {
        clearInterval(this.timer);
    }
}
</script>

<style>

body, html, #app {
    height: 100%;
    font-family: 'Lucida Console', sans-serif;
    background: #222;
    color: #eee;
    margin: 0;
    padding: 0;
}

#app {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Lucida Console', sans-serif;
}

.time {
    font-weight: 700;
    font-size: 200px;
    font-family: 'Lucida Console', sans-serif;
}

.date {
    font-size: 50px;
    margin: 16px;
}

select, option {
    font-size: 32px;
    font-family: 'Lucida Console', sans-serif;
    background: #222;
    color: white;
    padding: 8px;
    border: 0;
    cursor: pointer;
}

header {
    font-size: 40px;
    margin-bottom: 16px;
}

a {
    color: #eee;
}
</style>
